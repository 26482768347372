@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

table, th, td {
    border: none !important;
}

body {
    font-family: 'Dosis', sans-serif;
}

.hideScroll::-webkit-scrollbar{
    display: none;
}

.hideScroll{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.hideVerticalScroll{
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.hideVerticalScroll::-webkit-scrollbar{
    height: 12px;
    width: 12px;
    background: #ffffff22;
    border-radius: 15px;
}

.hideVerticalScroll::-webkit-scrollbar-thumb {
    background: #c6e4ff9d;
    border-radius: 15px;
}


.logo-font{
    font-family: 'Prompt', sans-serif;
}

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

th, td {
    padding: 5px;
    text-align: left;
  }

  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #dbdbdb6d;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #afcbff;
    border-radius: 100vh;
    border: 3px solid #edf2f7;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #ffaa00;
}
